import "./Footer.css";
import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      2024 © Bi on-chain. All rights reserved
    </footer>
  )
}

export default Footer;